import React, { useState, useEffect } from 'react';
import {loggedInUser, loggedInEmail, getEventLoc, userLoc} from "./information.js";
import Navbar from "./Navbar";
import Footer from "./Footer";
import FileUploadButton from "./FileUploadButton";
import LocationSearchInput from "./LocationSearchInput";
import axios from 'axios';
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ConfirmationModal from './ConfirmationModal.js';
import { uploadImage } from './Utilities/s3Handler';


dayjs.extend(customParseFormat);

// const { RangePicker } = DatePicker;

const secrets = require('./secrets.js');
const api = secrets.server_url;

const recurringOptions = ["N/A", "Daily", "Weekly", "Monthly"];
const ageOptions = ["N/A", "Youth", "HS/College", "Adult"];
const genderOptions = ["N/A", "Male", "Female"];
const organizerOptions = ["Masjid", "Community"];
const priceOptions = ["N/A", "Free", "$", "$$", "$$$"];

function CreateEvent() {
    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 890 ? false : true);
    const [confirmationModal, setConfirmationModal] = useState(false);


    const [eventData, setEventData] = useState({
        name: '',
        organization: loggedInUser.data,
        org_type: '',
        start_datetime: '',
        end_datetime: '',
        frequency: '',
        description: '',
        img: '',
        price: '',
        location: '',
        latitude: '',
        longitude: '',
        age_range: '',
        gender: '',
        isApproved: false,
        capacity: '',
    });

    


    useEffect(() => {
        setEventData(prevData => ({
            ...prevData,
            start_datetime: startTime ? `${date}T${startTime}` : date,
            end_datetime: endTime ? `${date}T${endTime}` : date
        }));
    }, [date, startTime, endTime]);



    const onDateChange = (date, dateString) => {
        setDate(dateString);
    };

    const onStartTimeChange = (time, timeString) => {
        if(time){
            const formattedTimeString = time.format('HH:mm');
            setStartTime(`${formattedTimeString}:00.000`);
        }
        else{
            setStartTime('')
        }
        
    };

    const onEndTimeChange = (time, timeString) => {
        if(time){
            const formattedTimeString = time.format('HH:mm');
            setEndTime(`${formattedTimeString}:00.000`);
        }
        else{
            setEndTime('')
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // //console.log(name)
        // //console.log(value)
        setEventData(prevData => ({
            ...prevData,
            [name]: value === 'N/A' ? '' : value
        }));
    };

    const handlePlaceSelected = async (place) => {
        //console.log(place)
        await setEventData(prevData => ({
            ...prevData,
            location: place.formatted_address
        }))

        if(place){
            //console.log("calling geteventloc")
            const coords = await getEventLoc(place.formatted_address)
            //console.log("coords:")
            //console.log(coords)
            // //console.log(eventData)
            // //console.log(eventData.location)
            // //console.log(coords)
            setEventData(prevData => ({
                ...prevData,
                latitude: coords.latitude
            }));
            setEventData(prevData => ({
                ...prevData,
                longitude: coords.longitude
            }));
        }
        
    };

    const handleFileSelect = async (file) => {
        setEventData(prevData => ({
            ...prevData,
            img: file
        }));
    };

    // const fetchSessionEmail = async () => {
    //     const response = await axios.get(`${api}getLoggedInEmail/`, {
    //       withCredentials: true, // Include cookies with requests
    //     });
    //     return response.data;
    // };

    const handleCreateEvent = async (e) => {
        e.preventDefault();

        if (!eventData.name || !eventData.start_datetime || !eventData.end_datetime || !eventData.location) {
            console.error('Please fill out all required fields.');
            return;
        }

        //console.log("look at this for time value error", eventData);

        
        
        setLoading(true);
        try {
            // const formData = new FormData();
            // for (const key in eventData) {
            //     formData.append(key, eventData[key]);
            // }

            const img_url = await uploadImage(eventData.img)
            eventData.img = img_url

            const response = await axios.post(`${api}events/`, eventData, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                }
            });
            //console.log('Event created successfully:', response.data);
            setConfirmationModal(true)
            // Handle success (e.g., redirect or clear form)
        } catch (error) {
            console.error('Error creating event:', error);
        } finally {
            setLoading(false);

            //send confirmation email

            
            
            // if(loggedInEmail.data){
            //     const payload = {
            //     email: loggedInEmail.data,
            //     eventName: eventData.name
            //     };

            //     try {
            //         const response = await axios.post(`${api}sendEmail`, payload, {
            //             headers: {
            //                 'Content-Type': 'application/json'
                            // 'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            //             }
            //         });
            //         //console.log('Response:', response.data);
            //     } catch (error) {
            //         console.error('Error:', error.response ? error.response.data : error.message);
            //     }
            // }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]);


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <>
        <div className="wrapper">
            <Navbar />
            <div className="ce-hero">
                <div className="ce-text">
                    <p className="ce-title">Create Your Event Here!</p>
                    <p className="subtext ce-subtext">
                        Bringing the community together has never been easier! Organize and share your events with fellow members of the Ummah, and find your tribe of people who share your interests. Just fill out the form below!
                    </p>
                </div>
            </div>

            <form onSubmit={handleCreateEvent} onKeyDown={handleKeyDown}>
                <div className="create-event-container">
                    <div className="grid-block-one">
                        <div className="event-name">
                            <label htmlFor="name">Event Name</label>
                            <input
                                type="text"
                                id="event"
                                name="name"
                                className="ce-input"
                                value={eventData.name}
                                onChange={handleChange}
                                placeholder='Event Name Here'
                                autoComplete="off"
                                required
                            />
                        </div>

                        <div className="location">
                            <label htmlFor="location">Location</label>
                            <LocationSearchInput
                                id="location"
                                name="location"
                                currentLoc={userLoc}
                                onPlaceSelected={handlePlaceSelected}
                                required
                            />
                        </div>


                        <div className="date-input">
                            <label htmlFor="start_datetime">Date</label>
                            <DatePicker className="date-picker" onChange={onDateChange}  required />
                            {/* <RangePicker className="date-picker" onChange={onDateChange} required /> */}

                        </div>

                        <div className="short1">
                            <label htmlFor="time">Start Time</label>
                            <TimePicker inputReadOnly={isMobile}  use12Hours format="h:mm a" className="time-picker" onChange={onStartTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} required />
                        </div>

                        <div className="short2">
                            <label htmlFor="extra"> End Time</label>
                            <TimePicker inputReadOnly={isMobile} use12Hours format="h:mm a" className="time-picker" onChange={onEndTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} required />
                        </div>

                        <div className="cost-input">
                            <label htmlFor="price">Cost</label>
                            <input
                                type="number"
                                maxLength="8"
                                id="cost"
                                name="price"
                                className="ce-input"
                                value={eventData.price}
                                onChange={handleChange}
                                placeholder='$0.00'
                                autoComplete="off"
                                required
                            />
                        </div>

                        <div className="capacity-input">
                            <label htmlFor="capacity">Capcity</label>
                            <input
                                type="number"
                                maxLength="8"
                                id="capacity"
                                name="capacity"
                                className="ce-input"
                                value={eventData.capacity}
                                onChange={handleChange}
                                placeholder='50 guests'
                                autoComplete="off"
                                required
                            />
                        </div>

                        <div className="upload">
                            <label>Upload Your Flyer</label>
                            <FileUploadButton onFileSelect={handleFileSelect}/>
                        </div>

                        <div className="description">
                            <label htmlFor="description">Description</label>
                            <textarea
                                className="textArea-input"
                                id="description"
                                name="description"
                                value={eventData.description}
                                onChange={handleChange}
                                 placeholder='Write your Description Here'
                                required
                            />
                        </div>
                    </div>

                    <div className="grid-block-two">
                        <div className="tag-selection">
                            <label className="tag-label">
                                Select Tags <p style={{ fontWeight: '400', color: '#333', marginLeft: '4px' }}>(if applicable)</p>
                            </label>
                            <div className="filter-tag">
                                <div className="tag-content">
                                    <div className="group-box">
                                        <p className="tag-groups">Organizers</p>
                                        <div className="chbx-group">
                                            {organizerOptions.map((option, index) => (
                                                <label key={index} htmlFor={`organization-${index}`} className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        id={`organization-${index}`}
                                                        name="org_type"
                                                        value={option}
                                                        onChange={handleChange}
                
                                                    />
                                                    <span className="c-label-r">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="group-box">
                                        <p className="tag-groups">Age</p>
                                        <div className="chbx-group">
                                            {ageOptions.map((option, index) => (
                                                <label key={index} htmlFor={`age_range-${index}`} className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        id={`age_range-${index}`}
                                                        name="age_range"
                                                        value={option}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="c-label-r">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="group-box">
                                        <p className="tag-groups">Gender</p>
                                        <div className="chbx-group">
                                            {genderOptions.map((option, index) => (
                                                <label key={index} htmlFor={`gender-${index}`} className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        id={`gender-${index}`}
                                                        name="gender"
                                                        value={option}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="c-label-r">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="group-box">
                                        <p className="tag-groups">Recurring</p>
                                        <div className="chbx-group">
                                            {recurringOptions.map((option, index) => (
                                                <label key={index} htmlFor={`frequency-${index}`} className="custom-radio">
                                                    <input
                                                        type="radio"
                                                        id={`frequency-${index}`}
                                                        name="frequency"
                                                        value={option}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="c-label-r">{option}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button-block">
                        <button 
                        className="join submit-event" 
                        type="submit"

                        >Submit Your Event</button>

                        <ConfirmationModal trigger={confirmationModal} setTrigger={setConfirmationModal} />
                    </div>

                </div>
            </form>
        </div>
        <Footer />
    </>
    );
}

export default CreateEvent;
