
import React, { useRef, useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { ReactComponent as Location } from './Location.svg';
import secrets from './secrets';

const LocationSearchInput = ({ onPlaceSelected, reset, currentLoc }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const apiKey = secrets.google_maps_api_key;


  useEffect(() => {
    // Function to initialize Google Places Autocomplete
    const initAutocomplete = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place) {
          onPlaceSelected(place);
          setInputValue(place.formatted_address || '');
        }
      });
    };

    // Load the Google Places Autocomplete library
    const loadGooglePlaces = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      script.onload = initAutocomplete;
      script.onerror = () => {
        console.error('Error loading the Google Maps script.');
      };
      document.head.appendChild(script);
    };

    if (!window.google) {
      loadGooglePlaces();
    } else {
      initAutocomplete();
    }
  }, [apiKey, onPlaceSelected]);

  useEffect(() => {
    if (reset) {
      setInputValue('');
    }
  }, [reset]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  console.log("curretn location look here", currentLoc);

  return (
    <>
    <div style={{ position: 'relative', display: 'flex'}}>
      
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className = "location-search-input"
        placeholder={currentLoc && currentLoc.city ? currentLoc.city : "Enter a Location"} 
        required
      />
      < Location style={{padding:'0', marginTop:'11px', display: 'flex', position:'absolute', alignItems: 'center', width: '16px', height:'16px', right: '10px', fill: '#bfbfbf' }} />
      </div>
        </>
  );
};

export default LocationSearchInput;




