import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import FileUploadButton from "./FileUploadButton";
import LocationSearchInput from "./LocationSearchInput";
import { DatePicker, TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import axios from "axios";
import { getEventLoc } from './information.js';
import Navbar from "./Navbar.js";
dayjs.extend(customParseFormat);

const recurringOptions = ["N/A", "Daily", "Weekly", "Monthly"];
const ageOptions = ["N/A", "Youth", "HS/College", "Adult"];
const genderOptions = ["N/A", "Male", "Female"];
const organizerOptions = ["N/A", "Masjid", "Community"];


const secrets = require("./secrets.js");
const api = secrets.server_url;

function EditEvent({ info, trigger, setTrigger }) {

    const [date, setDate] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [loading, setLoading] = useState(false);

    const [eventData, setEventData] = useState({
        name: info.name,
        organization: info.organization,
        org_type: info.org_type,
        start_datetime: info.start_datetime,
        end_datetime: info.end_datetime,
        frequency: info.frequency,
        description: info.description,
        img: info.img,
        price: info.price,
        location: info.location,
        latitude: info.latitude,
        longitude: info.longitude,
        age_range: info.age_range,
        gender: info.gender,
        isApproved: info.isApproved,
        capacity: info.capacity,
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        setEventData(prevData => ({
            ...prevData,
            start_datetime: startTime ? `${date}T${startTime}` : date,
            end_datetime: endTime ? `${date}T${endTime}` : date
        }));
    }, [date, startTime, endTime]);



    const onDateChange = (date, dateString) => {
        setDate(dateString);
    };

    const onStartTimeChange = (time, timeString) => {
        const formattedTimeString = time.format('HH:mm');
        setStartTime(`${formattedTimeString}:00.000`);

    };

    const onEndTimeChange = (time, timeString) => {
        const formattedTimeString = time.format('HH:mm');
        setEndTime(`${formattedTimeString}:00.000`);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // //console.log(name)
        //console.log(value)
        setEventData(prevData => ({
            ...prevData,
            [name]: value === 'N/A' ? '' : value
        }));
    };

    const handlePlaceSelected = async (place) => {

        await setEventData(prevData => ({
            ...prevData,
            location: place.formatted_address
        }))

        if (place) {
            const coords = await getEventLoc(place.formatted_address)
            // console.log(place)
            console.log(coords)
            // console.log(eventData)
            // console.log(eventData.location)
            // console.log(coords)
            await setEventData(prevData => ({
                ...prevData,
                latitude: coords.latitude
            }));
            await setEventData(prevData => ({
                ...prevData,
                longitude: coords.longitude
            }));
        }

    };

    const handleFileSelect = (file) => {
        setEventData(prevData => ({
            ...prevData,
            img: file
        }));
    };

    const updateEventDetails = async () => {
        console.log(eventData)
        // save new data
        const response = await axios.put(`${api}events/${info.event_id}`, eventData, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }

        });

        //console.log(response)
        // window.location.reload()
    };

    const handleDeleteEvent = async () => {
        const response = await axios.delete(`${api}events/${info.event_id}`, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
        });
        window.location.href = '/events';
    }

    const handleApproveEvent = async () => {
        try {
            const response = await axios.put(
                `${api}events/${info.event_id}`,
                { isApproved: true },
                {
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                        
                    },
                    withCredentials: true, // Optional: Include credentials if needed
                }
            );
            console.log('Event approved successfully:', response.data);
        } catch (error) {
            console.error('Error approving event:', error.message);
        }
    
        window.location.href = '/adminPanel';
    }


    return (trigger) ? (
        <>

            <div >
                <div class="registration-modal edit-event-modal">

                    {!info.isApproved && (
                        <button onClick={handleApproveEvent}>APPROVE EVENT</button>
                    )}

                    <div class="title-icon">
                        <p class="rm-title"> Edit event details </p>
                        <CloseIcon className="close" onClick={() => setTrigger(false)} />
                    </div>

                    {/* current data */}
                    <>
                        <p > Current data:</p>
                        <br></br>
                        <p ><strong>Event Name:</strong> {info.name}</p>
                        <p ><strong>Host Name:</strong> {info.organization}</p>
                        <p ><strong>Organization Type:</strong> {info.org_type}</p>
                        <p ><strong>Start Time:</strong> {info.start_datetime}</p>
                        <p ><strong>End Time:</strong> {info.end_datetime}</p>
                        <p ><strong>Frequency:</strong> {info.frequency}</p>
                        <p ><strong>Description:</strong> {info.description}</p>
                        <p ><strong>Image: </strong> {info.img}</p>
                        <p ><strong>Price:</strong> {info.price}</p>
                        <p ><strong>Tickets link:</strong> {info.tickets_link}</p>
                        <p > <strong>Location: </strong> {info.location}</p>
                        <p ><strong>Registered:</strong> {info.registered}</p>
                        <p ><strong>Age Range:</strong> {info.age_range}</p>
                        <p ><strong>Gender:</strong> {info.gender}</p>
                        <p ><strong>Capacity:</strong> {info.capacity}</p>
                    </>

                    {/* new data */}
                    <>
                        <form onSubmit={updateEventDetails} onKeyDown={handleKeyDown}>
                            <div className="create-event-container">
                                <div className="grid-block-one">
                                    <div className="event-name">
                                        <label htmlFor="name">Event Name</label>
                                        <input
                                            type="text"
                                            id="event"
                                            name="name"
                                            className="ce-input"
                                            value={eventData.name}
                                            onChange={handleChange}
                                            placeholder='Event Name Here'

                                        />
                                    </div>

                                    <div className="location">
                                        <label htmlFor="location">Location</label>
                                        <LocationSearchInput
                                            id="location"
                                            name="location"
                                            onPlaceSelected={handlePlaceSelected}

                                        />
                                    </div>


                                    <div className="date-input">
                                        <label htmlFor="start_datetime">Date</label>
                                        <DatePicker className="date-picker" onChange={onDateChange} />
                                    </div>

                                    <div className="short1">
                                        <label htmlFor="time">Start Time</label>
                                        <TimePicker use12Hours format="h:mm a" className="time-picker" onChange={onStartTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} />
                                    </div>

                                    <div className="short2">
                                        <label htmlFor="extra"> End Time</label>
                                        <TimePicker use12Hours format="h:mm a" className="time-picker" onChange={onEndTimeChange} defaultOpenValue={dayjs('00:00', 'HH:mm')} />
                                    </div>

                                    <div className="cost-input">
                                        <label htmlFor="price">Cost</label>
                                        <input
                                            type="number"
                                            maxLength="8"
                                            id="cost"
                                            name="price"
                                            className="ce-input"
                                            value={eventData.price}
                                            onChange={handleChange}
                                            placeholder='$0.00'

                                        />
                                    </div>

                                    <div className="capacity-input">
                                        <label htmlFor="capacity">Capcity</label>
                                        <input
                                            type="number"
                                            maxLength="8"
                                            id="capacity"
                                            name="capacity"
                                            className="ce-input"
                                            value={eventData.capacity}
                                            onChange={handleChange}
                                            placeholder='50 guests'

                                        />
                                    </div>

                                    <div className="upload">
                                        <label>Upload Your Flyer</label>
                                        <FileUploadButton onFileSelect={handleFileSelect} />
                                    </div>

                                    <div className="description">
                                        <label htmlFor="description">Description</label>
                                        <textarea
                                            className="textArea-input"
                                            id="description"
                                            name="description"
                                            value={eventData.description}
                                            onChange={handleChange}
                                        // placeholder='Write your Description Here'

                                        />
                                    </div>
                                </div>

                                <div className="grid-block-two">
                                    <div className="tag-selection">
                                        <label className="tag-label">
                                            Select Tags <p style={{ fontWeight: '400', color: '#333', marginLeft: '4px' }}>(if applicable)</p>
                                        </label>
                                        <div className="filter-tag">
                                            <div className="tag-content">
                                                <div className="group-box">
                                                    <p className="tag-groups">Organizers</p>
                                                    <div className="chbx-group">
                                                        {organizerOptions.map((option, index) => (
                                                            <label key={index} htmlFor={`organization-${index}`} className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id={`organization-${index}`}
                                                                    name="org_type"
                                                                    value={option}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="c-label-r">{option}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="group-box">
                                                    <p className="tag-groups">Age</p>
                                                    <div className="chbx-group">
                                                        {ageOptions.map((option, index) => (
                                                            <label key={index} htmlFor={`age_range-${index}`} className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id={`age_range-${index}`}
                                                                    name="age_range"
                                                                    value={option}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="c-label-r">{option}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="group-box">
                                                    <p className="tag-groups">Gender</p>
                                                    <div className="chbx-group">
                                                        {genderOptions.map((option, index) => (
                                                            <label key={index} htmlFor={`gender-${index}`} className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id={`gender-${index}`}
                                                                    name="gender"
                                                                    value={option}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="c-label-r">{option}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="group-box">
                                                    <p className="tag-groups">Recurring</p>
                                                    <div className="chbx-group">
                                                        {recurringOptions.map((option, index) => (
                                                            <label key={index} htmlFor={`frequency-${index}`} className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    id={`frequency-${index}`}
                                                                    name="frequency"
                                                                    value={option}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="c-label-r">{option}</span>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="button-block">
                                    <button className="join submit-event" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </>
                    <button onClick={handleDeleteEvent}>DELETE EVENT</button>


                    {/* <button class = "next-step-registration cancel-button" onClick={async () => {
                        // window.location.reload()
                        
                        window.location.reload()
                        }}> Save </button> */}


                </div>
            </div>
        </>
    ) : "";
}

export default EditEvent;