import React, { useState } from "react";
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';


function ConfirmationModal(props) {

    return (props.trigger) ? (
        <>
            <div class="registration-modal-wrapper">
                <div class="registration-modal">
                    <div class="title-icon">
                        <p class="rm-title"> Event Request Sent </p>
                        <SuccessIcon className="success-icon" />
                        <CloseIcon className="close" onClick={() => {
                            props.setTrigger(false) 
                            window.location.reload()}} />
                    </div>

                    <p class="rm-subtext cancel-subtext"> Your event is pending approval, you'll be notified by email when we have confirmed it.</p>

                </div>
            </div>
        </>
    ) : "";
}

export default ConfirmationModal;