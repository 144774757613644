import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F9FB;
  min-height: 100vh;
`;

const UmmahfyLogo = styled.svg`
  width: 300px;
  opacity: 0;
  animation: ${fadeInOut} 2s infinite ease-in-out;
`;

const Loading = (props) => {
  return (
    <Container>
      <UmmahfyLogo
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 520.6 315.21"
      >
        <defs>
          <style>
            {`.cls-1 { fill: #002244; }
              .cls-1, .cls-2 { stroke-width: 0px; }
              .cls-2 { fill: #fff; }`}
          </style>
        </defs>
        <rect className="cls-1" width="520.6" height="315.21" rx="16" ry="16" />
        <g>
          <path className="cls-2" d="M452.06,107.06l-37.19-37.19c-3.03-3.03-7.14-4.73-11.43-4.73h-51.11c-6.38,0-11.55,5.17-11.55,11.55v161.84c0,6.38,5.17,11.55,11.55,11.55h46.25c6.38,0,11.55-5.17,11.55-11.55v-100.19c0-6.38,5.17-11.55,11.55-11.55h22.21c10.29,0,15.44-12.44,8.17-19.72Z" />
          <path className="cls-2" d="M308.03,134.3h-92.55c-6.38,0-11.56,5.18-11.56,11.56v45.98c0,5.52-3.74,10.53-9.15,11.62-7.44,1.5-13.97-4.14-13.97-11.32v-115.08c0-6.38-5.18-11.56-11.56-11.56h-92.55c-6.38,0-11.56,5.18-11.56,11.56v92.55c0,6.38,5.18,11.56,11.56,11.56h46.28c6.38,0,11.56,5.18,11.56,11.56v45.57c0,6.38,5.18,11.56,11.56,11.56h34.71v.11h127.23c6.38,0,11.56-5.18,11.56-11.56v-92.55c0-6.38-5.18-11.56-11.56-11.56ZM134.53,123.34c0,6.38-5.18,11.56-11.56,11.56h-.02c-6.38,0-11.56-5.18-11.56-11.56v-.02c0-6.38,5.18-11.56,11.56-11.56h.02c6.38,0,11.56,5.18,11.56,11.56v.02ZM273.32,192.14c0,6.38-5.18,11.56-11.56,11.56h-.02c-6.38,0-11.56-5.18-11.56-11.56v-.02c0-6.38,5.18-11.56,11.56-11.56h.02c6.38,0,11.56,5.18,11.56,11.56v.02Z" />
          <path className="cls-2" d="M313.84,65.76h-18.75c-3.18,0-5.45,2.68-5.52,5.86-.06,3.12-2.61,5.63-5.74,5.63h-1.59c-3.18,0-5.45-2.68-5.52-5.86s-2.61-5.63-5.74-5.63h-18.72c-3.18,0-5.45,2.68-5.52,5.86-.06,3.12-2.61,5.63-5.74,5.63h-1.59c-3.18,0-5.45-2.68-5.52-5.86-.06-3.12-2.61-5.63-5.74-5.63h-18.5c-3.17,0-5.74,2.57-5.74,5.74v34.78c0,3.17,2.57,5.74,5.74,5.74h104.18c3.17,0,5.74-2.57,5.74-5.74v-34.78c0-3.17-2.57-5.74-5.74-5.74Z" />
        </g>
      </UmmahfyLogo>
    </Container>
  );
};

export default Loading;
