const secrets = {
    server_url: process.env.REACT_APP_SERVER_URL,
    s3_acces_key_id: process.env.REACT_APP_S3_ACCES_KEY_ID,
    s3_secretAccessKey: process.env.REACT_APP_S3_SECRETACCESSKEY,
    google_maps_api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    ipgeolocation_api_key: process.env.REACT_APP_IPGEOLOCATION_API_KEY
    
}



module.exports = secrets