import React from 'react';
import { ReactComponent as Copy } from "./Copy.svg";

const ShareButton = (props) => {
  const handleShare = async () => {
    const url = window.location.href; // Get the current page URL

    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          text: 'Check out this page!',
          url: url,
        });
        //console.log('Shared successfully');
      } catch (error) {
        console.error('Error sharing:', error.message);
      }
    } else {
      // Fallback for browsers that do not support navigator.share
      try {
        await navigator.clipboard.writeText(url);
        //console.log('URL copied to clipboard');
        alert('Link copied to clipboard!');
      } catch (error) {
        console.error('Error copying to clipboard:', error.message);
      }
    }
  };

  return (
    <button onClick={handleShare} className = {props.styling} >
      <Copy className={props.iconstyling}/>
      {props.buttontext}
    </button>
  );
};

export default ShareButton;