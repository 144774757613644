import Navbar from "./Navbar"; 
import Footer from "./Footer";

function ErrorPage() {

    return (
        <>
        <div class="wrapper error-wrapper">
            <Navbar />
            <div class="error-div">
                <h1> 404 Something Went Wrong</h1>
                <h3> Use the navigation bar to go back to the main sections of the site. </h3>
            </div>
        </div>
        <Footer />
        </>
    )

}

export default ErrorPage;