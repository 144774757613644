import React from 'react';
import { ReactComponent as Linkedin } from './Linkedin.svg';
import { ReactComponent as Instagram } from './Instagram.svg';
import { ReactComponent as Twitterx } from './Twitterx.svg';
import { ReactComponent as TikTok } from './TikTok.svg';

const Footer = () => {
  const footerStyle = {
    paddingTop: '24px',
    paddingBottom: '24px',
    backgroundColor: '#002244',
    color: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',

  };

  return (
    <footer style={footerStyle}>

      <p style={{ marginBottom: '8px' }}> Follow Us On </p>
      <div style={{ display: 'flex', justifyContent: 'center', }}>
        <a href="https://www.linkedin.com/company/ummahfy/" target="_blank" rel="noopener noreferrer">
          <Linkedin style={{ width: '36px', cursor: 'pointer' }} />
        </a>
        {/* Instagram */}
        <a href="https://www.instagram.com/ummahfy/" target="_blank" rel="noopener noreferrer">
          <Instagram style={{ width: '36px', marginLeft: '16px', cursor: 'pointer' }} />
        </a>
        {/* Twitter */}
        <a href="https://twitter.com/UmmahfyOfficial" target="_blank" rel="noopener noreferrer">
          <Twitterx style={{ width: '36px', marginLeft: '16px', cursor: 'pointer' }} />
        </a>
        {/* TikTok */}
        <a href="https://www.tiktok.com/@ummahfy" target="_blank" rel="noopener noreferrer">
          <TikTok style={{ margin: '0', padding: '0', width: '30px', marginTop: '10px', marginLeft: '16px', cursor: 'pointer', filter: 'invert(100%)' }} />
        </a>
      </div>

      <p style={{ marginTop: '8px' }}>
        Email us at: <a style={{color: '#fff'}}href="mailto:events@ummahfy.com">events@ummahfy.com</a>
      </p>



    </footer>
  );
};

export default Footer;

