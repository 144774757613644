import EventCard from "./EventCard";
import React, { useState, useEffect } from "react";
import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Location } from './Location.svg';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { informationWithPastEvents, loggedInUser, loggedInEmail, userInfo } from './information';
import addresses from './information';
import Footer from './Footer';
//const information = require("./information.js");
import Attendees from "./Attendees";
import axios from "axios";
import ShareButton from "./ShareButton.js";
import RegisterModal from "./RegisterModal.js";
import EditRSVP from "./EditRSVP.js";
import SignUp from "./SignUp.js";



const secrets = require("./secrets.js");
const api = secrets.server_url;

const Profile = () => {
  const [user, setUser] = useState(loggedInUser.data);
  const [email, setEmail] = useState(loggedInEmail.data);
  const [userDetails, setUserDetails] = useState(userInfo ? userInfo : null); // Initialize as null
  const [loading, setLoading] = useState(false); // Add a loading state


  // useEffect(() => {
  //   async function fetchUserDetails(email) {
  //     try {
  //       const response = await axios.get(`${api}users/${email}/`);
  //       await setUserDetails(response.data.data[0]);
  //       // //console.log(userDetails)
  //     } catch (error) {
  //       console.error(error);
  //     } finally {
  //       setLoading(false); // Set loading to false regardless of success or failure
  //     }
  //   }

  //   fetchUserDetails(email);
  // }, [user]);

  function createCard(info) {
    return (
      <Link key={info._id} to={`/event/${info._id}`} className="card-link">
        <EventCard
          imgurl={info.img}
          event={info.name}
          masjid={info.organization}
          date={info.timeArray[0]}
          description={info.description}
          age={info.age_range}
          frequency={info.frequency}
          price={info.price}
          gender={info.gender}
          registered={info.registered}
        />
      </Link>
    );
  }

  if (loading) {
    return <div></div>; // Display a loading message while data is being fetched
  }

  if (!userDetails) {
    return <div>Error loading user details.</div>; // Display an error message if user details couldn't be fetched
  }

  //console.log(userDetails)

  // function filterEventsByNames(events, searchNames) {
  //   // Convert searchNames to lowercase for case-insensitive comparison
  //   const lowerCaseSearchNames = searchNames.map(name => name.trim().toLowerCase());

  //   // Filter events that match any of the search names
  //   return events.filter(event => 
  //     lowerCaseSearchNames.includes(event.name.trim().toLowerCase())
  //   );
  // }

  // function filterEventsByIds(events, searchIds) {
  //   // Filter events that match any of the search IDs
  //   //console.log("Events: ", events);
  //   //console.log("Search IDs: ", searchIds);
  //   return events.filter(event => 
  //     searchIds.includes(event.event_id)
  //   );
  // }

  // // const filteredEvents = filterEventsByNames(information, userDetails.signed_up_events);
  // const filteredEvents = filterEventsByIds(information, userDetails.signed_up_events);

  // //console.log(userDetails.signed_up_events, "signed up events")
  // //console.log(filteredEvents, "filtered events")

  const searchIds = userDetails.signed_up_events.filter(event => !isNaN(event));
  const searchCreatedIds = userDetails.createdEvents.filter(event => !isNaN(event));
  const searchNames = userDetails.signed_up_events.filter(event => isNaN(event)).map(name => name.trim().toLowerCase());

  //console.log(searchIds)
  //console.log(information)

  // function filterEventsByNames(events, searchNames) {
  //   return events.filter(event => searchNames.includes(event.name.trim().toLowerCase()));
  // }

  function filterEventsByIds(events, searchIds) {
    //console.log('Events:', events);
    //console.log('Search IDs (Inside filter):', searchIds);
    return events.filter(event => searchIds.includes(event.event_id.toString()));
  }

  // const filteredEventsByNames = filterEventsByNames(information, searchNames);
  const filteredEventsByIds = filterEventsByIds(informationWithPastEvents, searchIds);

  const filteredCreatedEvents = filterEventsByIds(informationWithPastEvents, searchCreatedIds);

  // Combine both filtered results
  // const filteredEvents = [...filteredEventsByNames, ...filteredEventsByIds];

  //console.log(userDetails.signed_up_events, "signed up events");
  //console.log(userDetails.createdEvents, "created events");
  //console.log(filteredEventsByIds, "filtered events");

  return (
    <>
      <div className="wrapper">
        <Navbar />
        <div className="profile-container">
          <div className="profile-header">
            <svg class="profile-image" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM6.023 15.416C7.491 17.606 9.695 19 12.16 19c2.464 0 4.669-1.393 6.136-3.584A8.968 8.968 0 0 0 12.16 13a8.968 8.968 0 0 0-6.137 2.416zM12 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </g>
            </svg>
            {/* <img src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg" alt="Profile" className="profile-image" /> */}
            <div className="profile-details">
              <h2>{userDetails.username}</h2>
              {/* <p>{userDetails.email}</p> */}
              <p>{userDetails.signed_up_events.length} Events signed up</p>
            </div>
          </div>
          <div className="profile-hosting">
            <h2 class="registered-events-title">Registered Events</h2>
            {filteredEventsByIds.map(createCard)}
          </div>
        </div>
      </div>
      <Footer> </Footer>
    </>
  );
};

export default Profile;
