import React, { useState, useEffect } from "react";
import axios from 'axios';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import Signin from './Signin.js';
import { ReactComponent as GoogleLogo } from './GoogleLogo.svg';

const secrets = require('./secrets.js');
const api = secrets.server_url;

async function createUser(username, email, password, phone, birthday, email_notifs, text_notifs, gender, setError) {
    try {
        const response = await axios.post(`${api}users/`, null, {
            params: {
                username,
                email,
                password,
                phone,
                birthday,
                email_notifs,
                text_notifs,
                gender
            },
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            },
            withCredentials: true // Include cookies with requests
        });
        if (response.data.data === 'email exists') {
            setError('This email already exists.');
        } else {
            window.location.reload();
        }
        return JSON.stringify(response.data);
    } catch (error) {
        console.error(error);
        throw error; // Handle or rethrow error
    }
}

async function handleSignUp(username, password, email, setError) {
    const response = await createUser(username, email, password, "", "", false, false, "", setError);
    //console.log(response);
}

function SignUp(props) {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [buttonSignin, setButtonSignin] = useState(false);


    useEffect(() => {
        if (props.trigger) {
            // Disable scrolling when the modal is open
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling when the modal is closed
            document.body.style.overflow = '';
        }

        // Cleanup function to ensure scrolling is enabled when component unmounts
        return () => {
            document.body.style.overflow = '';
        };
    }, [props.trigger]);

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if passwords match
        if (password !== confirmPassword) {
            setPasswordMatchError('Passwords do not match');
            return;
        }

        // Reset error messages
        setPasswordMatchError('');
        setEmailError('');

        // Handle sign up
        handleSignUp(fullName, password, email, setEmailError);
    };

    async function handleGoogleSignup() {
        window.location.href = api + "auth/google";
    }

    const handleClose = () => {
        props.setTrigger(false);
        setPasswordMatchError(''); // Clear password match error
        setEmailError(''); // Clear email error
        setFullName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        
    };

    return (
        <>
            {props.trigger && !buttonSignin && (
                <div>
                    <div className="signup-wrapper">
                        <div className="signup-page">
                            <div className="left-part">
                                <div className="sign-title">
                                    <p>Sign Up to Ummahfy</p>
                                    <CloseIcon className="close" onClick={handleClose} />
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="inputs">
                                        <label htmlFor="fullName">Full Name or Organization</label>
                                        <input
                                            type="text"
                                            id="fullName"
                                            name="fullName"
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                            className="long-input"
                                            required
                                        />
                                    </div>

                                    <div className="inputs">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="long-input"
                                            required
                                        />
                                    </div>

                                    <div className="inputs">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="long-input"
                                            required
                                        />
                                    </div>

                                    <div className="inputs">
                                        <label htmlFor="confirm">Confirm Password</label>
                                        <input
                                            type="password"
                                            id="confirm"
                                            name="confirm"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="long-input"
                                            required
                                        />
                                    </div>

                                    {passwordMatchError && <p className="error-message">{passwordMatchError}</p>}
                                    {emailError && <p className="error-message">{emailError}</p>}

                                    {/* <div className="t-c">
                                        <label htmlFor="terms" className="custom-checkbox terms-cond">
                                            <input
                                                type="checkbox"
                                                id="terms"
                                                name="terms"
                                                required
                                            />
                                            <span className="checkmark"></span>
                                            <p className="terms">By checking this box, I acknowledge that I have read and agree to abide <br/> by the terms and conditions outlined in the Terms of Service.</p>
                                        </label>
                                    </div>
                                     */}
                                    <button type="submit" className="create-acc">Create Account</button>
                                </form>

                                <div className="divider">OR</div>

                                <button className="signin google" onClick={handleGoogleSignup}>
                                    <GoogleLogo className="google-logo" />
                                    Continue with Google
                                </button>
                                
                                <button
                                    className="reg"
                                    onClick={() => {
                                        props.setTrigger(false);
                                        setButtonSignin(true);
                                    }}
                                >
                                    Already Registered? <b>Sign In Here</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {buttonSignin && (
                <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
            )}
        </>
    );
}

export default SignUp;
