import React, { useState, useRef } from 'react';

const FileUploadButton = ({ onFileSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    onFileSelect(event.target.files[0]);
  };

  const handleButtonClick = (event) => {
    event.preventDefault(); // Prevents form submission if inside a form
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <button
        className='file-upload-button'
        onClick={handleButtonClick}
      >
        Add File
      </button>
      {selectedFile && <p className="file-selected">Selected file: {selectedFile.name}</p>}
    </div>
  );
};

export default FileUploadButton;
