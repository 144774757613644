import React from "react";

function HomeCard(props) {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const formatPrice = (price) => {
        // Check if price is 0 or "Free" and return "Free" directly
        if (price === "0"|| price === "Free") {
          return "Free";
        }
      
        // Convert price to string if it's a number
        let priceStr = price.toString();
      
        // Add '$' if it's not already present
        if (!priceStr.startsWith("$")) {
          priceStr = `$${priceStr}`;
        }
      
        return priceStr;
      };
      

    return (
        <div class = "card-home">

                <div class="img-mask"> 
                    <img src = {props.imgurl} />
                </div>
                                


            <div class = "card-home-right">
                <div class= "event"> 
                    {props.event}
                </div>

                <div class="masjid"> {props.masjid} </div>

                <div class = "date"> 
                    <p>  {props.date} </p>
                </div>
                
                <div class="badge-group">
               
                    {props.frequency && props.frequency !== 'once' && (
                        <div className="badge time-badge">
                            <p>{capitalizeFirstLetter(props.frequency)}</p>
                        </div>
                        )}

                    {props.age && props.age !== 'all' && (
                        <div className="badge age-badge">
                            <p>{capitalizeFirstLetter(props.age)}</p>
                        </div>
                        )}
                    
                    {props.gender && props.gender !== 'all' && (
                        <div className="badge gender-badge">
                            <p>{capitalizeFirstLetter(props.gender)}</p>
                        </div>
                        )}

                    {props.price && (
                        <div class = "badge price-badge"  > <p>{formatPrice(props.price)}</p> </div>
                    )}

                        
                        {/* <div class = "badge time-badge" > <p> {props.frequency} </p> </div> */}
                        {/* <div class = "badge age-badge" > <p> {props.age} </p></div>   */}
                        {/* <div class="badge gender-badge" > {props.gender} </div>            */}
                        {/* <div class="badge organizer-badge" > Masjid </div>                                              */}
                    
                </div>
            </div>




            
        </div>

    )
    
}

export default HomeCard;