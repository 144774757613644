import React from "react";
import { ReactComponent as IconUmmahfy } from './IconUmmahfy.svg';
import { ReactComponent as IconUmmahfyTwo } from './IconUmmahfyTwo.svg';
import { ReactComponent as IconUmmahfyThree } from './IconUmmahfyThree.svg';

function EventCard(props) {

  function capitalizeFirstLetter(string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  //console.log("screen size", props.screenSize)

  const formatPrice = (price) => {
    console.log("check if price is 0 or free",price)
    // Check if price is 0 or "Free" and return "Free" directly
    if (price === "0" || price === "Free") {
      return "Free";
    }
  
    // Convert price to string if it's a number
    let priceStr = price.toString();
  
    // Add '$' if it's not already present
    if (!priceStr.startsWith("$")) {
      priceStr = `$${priceStr}`;
    }
  
    return priceStr;
  };
  

  return (
    <div class="e-card-home">
      <div class="e-img-mask">
        <img src={props.imgurl} />
      </div>

      <div class="e-right">
        <div class="e-event">
          {props.event}
        </div>

        <div class="e-masjid"> {props.masjid} </div>

        <div className="e-date">
          <p>{props.date}</p>
        </div>


        <div className="badge-group e-badges">
          {props.frequency && props.frequency !== 'once' && (
            <div className="badge time-badge">
              <p>{capitalizeFirstLetter(props.frequency)}</p>
            </div>
          )}
          {props.age && props.age !== 'all' && (
            <div className="badge age-badge">
              <p>{capitalizeFirstLetter(props.age)}</p>
            </div>
          )}
          {props.gender && props.gender !== 'all' && (
            <div className="badge gender-badge">
              <p>{capitalizeFirstLetter(props.gender)}</p>
            </div>
          )}
          {props.price && (
            <div className="badge price-badge">
              <p>{formatPrice(props.price)}</p>
            </div>
          )}
        </div>




        <div class="e-desc">
          <p> {props.description} </p>
        </div>

        <div class="e-bottom">
          <div class="e-attendees">
            <IconUmmahfy style={{ width: '16px', marginRight: '-3px', }} />
            <IconUmmahfyTwo style={{ width: '16px', marginRight: '-3px', fill: '#fff' }} />
            <IconUmmahfyThree style={{ width: '16px', marginRight: '0px', }} />
            <p style={{ marginLeft: '4px' }}> {props.registered.length} going </p>
          </div>

        </div>
      </div>

    </div>

  )

}

export default EventCard;