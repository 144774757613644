import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import axios from 'axios';
import SignUp from "./SignUp.js";
import { ReactComponent as GoogleLogo } from './GoogleLogo.svg';

const secrets = require('./secrets.js');
const api = secrets.server_url;

async function handleSignin(email, password, setError) {
    const users = (await axios.get(`${api}users/`, {
        headers: {
            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
    })).data.data;

    const user = users.find(user => user.email === email);

    if (!user) {
        setError('No user found with this email');
        return false;
    }

    const data = { password };
    try {
        const response = await axios.post(api + 'users/' + email, data, {
            withCredentials: true,
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            }
        });

        if (response.data.data === 'passwords match') {
            window.location.reload();
        } else {
            setError('Passwords do not match!');
        }
    } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
    }
}

function Signin(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonSignUp, setButtonSignUp] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (props.trigger) {
            // Disable scrolling when the modal is open
            document.body.style.overflow = 'hidden';
        } else {
            // Enable scrolling when the modal is closed
            document.body.style.overflow = '';
        }

        // Cleanup function to ensure scrolling is enabled when component unmounts
        return () => {
            document.body.style.overflow = '';
        };
    }, [props.trigger]);

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSignin(email, password, setError);
    };

    async function handleGoogleLogin() {
        window.location.href = api + "auth/google";
    }

    const handleClose = () => {
        props.setTrigger(false);
        setError(''); // Clear the error message when closing the component
    };

    return (
        <>
            {props.trigger && !buttonSignUp && (
                <div>
                    <div className="signin-wrapper">
                        <div className="signin-page">
                            <div className="left-part">
                                <div className="sign-title">
                                    <p>Sign In to Ummahfy</p>
                                    <CloseIcon className="close" onClick={handleClose} />
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="inputs">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            required
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="long-input"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="inputs">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            required
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="long-input"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>

                                    {error && <div className="error-message">{error}</div>}
{/* 
                                    <div className="t-c">
                                        <label htmlFor="terms" className="custom-checkbox terms-cond">
                                            <input type="checkbox" id="terms" name="terms" required />
                                            <span className="checkmark"></span>
                                            <p className="terms">By checking this box, I acknowledge that I have read and agree to abide <br /> by the terms and conditions outlined in the Terms of Service.</p>
                                        </label>
                                    </div> */}

                                    <button className="signin">Sign In</button>
                                </form>

                                <div className="divider">OR</div>
                                <button className="signin google" onClick={handleGoogleLogin}>
                                    <GoogleLogo className="google-logo" />
                                    Continue with Google
                                </button>

                                <button
                                    className="reg"
                                    onClick={() => {
                                        props.setTrigger(false);
                                        setButtonSignUp(true);
                                    }}
                                >
                                    Not Registered? <b>Create Your Account Here</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {buttonSignUp && (
                <SignUp trigger={buttonSignUp} setTrigger={setButtonSignUp} />
            )}
        </>
    );
}

export default Signin;
