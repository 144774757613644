import React, { useState, useEffect } from 'react';
import { information, processLocations, getEventLoc, isAdmin, unapprovedEvents } from './information';
import EventCard from './EventCard';
import { Link } from "react-router-dom";
import Navbar from './Navbar';
import Footer from './Footer';
import FilterPanel from './FilterPanel';
import { DatePicker, Space } from 'antd';
import noEventsImage from './NoEvents.jpg';
import { ReactComponent as NoEvents } from './NoEvents.svg';

const recurring = ["N/A", "Daily", "Weekly", "Monthly"];
const age = ["N/A", "Youth", "HS/College", "Adult"];
const gender = ["N/A", "Male", "Female"];
const organizer = ["N/A", "Masjid", "Community"];
const price = ["N/A", "Free", "$", "$$", "$$$"];

function createCard(info) {
    return (
        <Link key={info._id} to={`/event/${info._id}`} className="card-link">
            <EventCard
                imgurl={info.img}
                event={info.name}
                masjid={info.organization}
                date={info.timeArray[0]}
                description={info.description}
                age={info.age_range}
                frequency={info.frequency}
                price={info.price}
                gender={info.gender}
                registered={info.registered}
            />
        </Link>
    );
}

function AdminPanel() {
    const [currentPage, setCurrentPage] = useState(1);
    const eventsPerPage = Number.MAX_SAFE_INTEGER; // Number of events to display per page
    //console.log(eventsPerPage, "max number")


    // const [filteredInfo, setFilteredInfo] = useState(information);






    const indexOfLastEvent = currentPage * eventsPerPage;
    const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
    // const currentEvents = filteredInfo.slice(indexOfFirstEvent, indexOfLastEvent);

    const handleChange = (date) => {
        //console.log('Selected date:', date);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    //console.log("information array", information)

    console.log(isAdmin)


    return (

        <>

            {isAdmin ? (
                <div>
                    <div className="wrapper">
                        <Navbar />
                        <div className="filter-calendar">
                            <div className="grid-eventcards">
                                <div className="header-pagination">
                                    <h1 className="event-header">Unapproved Events</h1>
                                </div>

                                {unapprovedEvents.map(createCard)}
                                {unapprovedEvents.length === 0 && (
                                    <>
                                        <div class="no-events-div">
                                            {/* <img src={noEventsImage} alt="No events" style={{ width: '100%' }} /> */}
                                            <p>no unapproved events</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            ) : (
                <div>
                    <h>access denied</h>
                </div>
            )}
        </>
    );
}

export default AdminPanel;
