import React, { useState } from "react";
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { useNavigate } from 'react-router-dom'; // For React Router
import ShareButton from "./ShareButton";


function RegisterModal(props) {


    const ensureHttps = (url) => {
        if (!url.startsWith("https://")) {
            return "https://" + url;
        }
        return url;
    };

    const externalLink = ensureHttps(props.info.tickets_link);
    // const externalLink = "google.com"

    //console.log("THIS IS THE EXTERNAL LINK", externalLink);

    // const shouldRender = true;

    const shouldRender =
        props.info.tickets_link !== null &&
        props.info.tickets_link !== "N/A" &&
        props.info.tickets_link !== "";

    return (props.trigger) ? (
        <>
            <div class="registration-modal-wrapper">
                <div class="registration-modal">
                    <div class="title-icon">
                        <p class="rm-title"> You're Going! </p>
                        <SuccessIcon className="success-icon" />
                        <CloseIcon
                            className="close"
                            onClick={async () => {
                                await props.setTrigger(false);
                                window.location.reload();
                            }}
                        />
                    </div>

                    {shouldRender ? (
                        <>
                            <p className="rm-button-label"> A confirmation email has been sent to you! This event requires further registration:</p>
                            <a href={externalLink} className="next-step-link" target="_blank" rel="noopener noreferrer">
                                <button className="next-step-registration">Click Here to Continue</button>
                            </a>
                        </>
                    ) : (
                        <p class="rm-subtext"> A confirmation email has been sent to you! </p>
                    )}


                    <ShareButton buttontext="Copy Link" styling="register-modal-share" iconstyling="rm-share-button-icon" />



                </div>
            </div>
        </>
    ) : "";
}

export default RegisterModal;