import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./Home";
import Events from "./Events";
import AdminPanel from "./AdminPanel";
import EventShell from "./EventShell";
import CreateEvent from "./CreateEvent";
import Profile from "./Profile";
import TestCreateEvent from "./TestCreateEvent";
import Loading from "./Loading"; // Adjust the path as needed
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "./ErrorPage";
import "./styles.css";
import { information } from "./information";

const redirectToExternalBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (userAgent.match(/Instagram/i)) {
    if (/android/i.test(userAgent)) {
      window.location.href = "intent://www.ummahfy.com#Intent;scheme=https;package=com.android.chrome;end";
    } 
    // else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    //   window.location.href = "https://www.ummahfy.com";
    // } else {
    // }
  }
};

function App() {
  const info = information;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [minLoadDuration] = useState(900); // Minimum duration to show loading screen (1 second)
  const [maxLoadDuration] = useState(10000); // Maximum loading time (10 seconds)

  useEffect(() => {
    if (location.pathname === '/') {
      if (isInitialLoad) {
        // Start loading and record start time
        setIsLoading(true);
        setStartTime(Date.now());

        const initialLoadTimeout = setTimeout(() => {
          setIsLoading(false);
          setIsInitialLoad(false); // Update flag after initial load
        }, minLoadDuration);

        // Ensure cleanup on component unmount
        return () => clearTimeout(initialLoadTimeout);
      }
    } else {
      // Ensure loading is false for other routes
      setIsLoading(false);
      setIsInitialLoad(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isLoading && startTime) {
      // Check if the loading time has exceeded the minimum duration
      const elapsedTime = Date.now() - startTime;
      const remainingTime = Math.max(0, maxLoadDuration - elapsedTime);

      // If the elapsed time is less than the maximum load duration, extend loading screen
      if (remainingTime > 0) {
        const extendLoadTimeout = setTimeout(() => {
          setIsLoading(false);
        }, remainingTime);

        // Cleanup on component unmount
        return () => clearTimeout(extendLoadTimeout);
      }
    }
  }, [isLoading, startTime]);
  
  useEffect(() => {
    redirectToExternalBrowser();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ScrollToTop /> {/* Ensure this is rendered above your Routes */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event/:id" element={<EventShell />} />
            <Route path="/createEvent" element={<CreateEvent />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/adminPanel" element={<AdminPanel />} />
            {/* <Route path="/createEvent" element={<TestCreateEvent />} /> */}
          </Routes>
        </>
      )}
    </>
  );
}

const AppWithRouter = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default AppWithRouter;
